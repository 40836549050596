import { addMonths, format, startOfMonth, startOfYear } from 'date-fns'

import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchProductionStore = defineStore('searchProductionStore', {
  state: () => ({
    subsidyApproved: null as string | null,
    status: 'VALIDATION' as string | null,
    animalTypeId: null as number | null,
    labelTypeId: null as number | null,
    validFrom: format(startOfYear(new Date()), 'yyyy-MM-dd') as string,
    validUntil: null as string | null,
    isCorrection: null as boolean | null,
    complied: null as boolean | null,
    loadPreviousMonths: null as string | null,
    createdFrom: null as string | null,
    createdUntil: null as string | null,
    quantityFrom: null as number | null,
    quantityTo: null as number | null,
    accumulate: null as string | null,
    latestRowsOnly: true,
    milkQualityTesting: null as boolean | null,
    sapNo: null as number | null,
    correction: null as number | null,
    period: format(addMonths(startOfMonth(new Date()), -1), 'yyyy-MM-dd') as string | null,

    items: [
      { key: 'subsidyApproved', operation: ':', itemType: 'string' },
      { key: 'status', operation: ':', itemType: 'string', defaultValue: 'VALIDATION' },
      { key: 'animalTypeId', operation: ':', itemType: 'number' },
      { key: 'labelTypeId', operation: ':', itemType: 'number' },
      { key: 'validFrom', operation: '>=', defaultValue: format(startOfYear(new Date()), 'yyyy-MM-dd') as string },
      { key: 'validUntil', operation: '<=' },
      { key: 'isCorrection', operation: ':', itemType: 'boolean' },
      { key: 'complied', operation: ':', itemType: 'boolean' },
      { key: 'loadPreviousMonths', operation: ':', itemType: 'string' },
      { key: 'createdFrom', operation: ':' },
      { key: 'createdUntil', operation: ':' },
      { key: 'quantityFrom', operation: ':' },
      { key: 'quantityTo', operation: ':' },
      { key: 'accumulate', operation: ':' },
      { key: 'latestRowsOnly', operation: ':', itemType: 'boolean' },
      { key: 'milkQualityTesting', operation: ':', itemType: 'boolean' },
      { key: 'sapNo', operation: ':' },
      { key: 'correction', operation: ':' },
      { key: 'period', operation: ':', itemType: 'string', defaultValue: format(addMonths(startOfMonth(new Date()), -1), 'yyyy-MM-dd') }
    ] as TermItemDefinition[]
  })
})
